import React, { Component } from 'react';
import "../asste/css/WeChat.css"
import {  } from 'antd';
import { } from '@ant-design/icons';


class WeChat extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return ( 
            <div className="weChat-div">  
                    <div className ="weChat-div-img">
                        
                    </div>
            </div>
        );
    }
}

export default WeChat;