import React ,{Component} from 'react';
import "../asste/css/Foots.css"
class Foots extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div className="Foots-div">
                <span>Copyright © 2016 天津仁和嘉境科技有限公司 津ICP备17003569号</span>

            </div>
        );
    }
}

export default Foots;